// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArQuickLinks-module--ar-h1-mobile--2oUee";
export var arH2Mobile = "ArQuickLinks-module--ar-h2-mobile--2vVsp";
export var arH3Mobile = "ArQuickLinks-module--ar-h3-mobile--VDh6V";
export var arH4Mobile = "ArQuickLinks-module--ar-h4-mobile--3runm";
export var arH5Mobile = "ArQuickLinks-module--ar-h5-mobile--uzO18";
export var arH1 = "ArQuickLinks-module--ar-h1--37Za-";
export var arH2 = "ArQuickLinks-module--ar-h2--3MhJi";
export var arH3 = "ArQuickLinks-module--ar-h3--yyb8k";
export var arH4 = "ArQuickLinks-module--ar-h4--2Q5Bm";
export var arH5 = "ArQuickLinks-module--ar-h5--3d-rH";
export var arParagraphXl = "ArQuickLinks-module--ar-paragraph-xl--ApWJI";
export var arParagraphXlMedium = "ArQuickLinks-module--ar-paragraph-xl-medium--3dW-T";
export var arParagraphBase = "ArQuickLinks-module--ar-paragraph-base--3Wu8v";
export var arParagraphBaseMedium = "ArQuickLinks-module--ar-paragraph-base-medium--11j6Q";
export var arParagraphSmall = "ArQuickLinks-module--ar-paragraph-small--2Tw1L";
export var arParagraphSmallMedium = "ArQuickLinks-module--ar-paragraph-small-medium--1MMLH";
export var arParagraphXsmall = "ArQuickLinks-module--ar-paragraph-xsmall--1Rt3e";
export var arParagraphXsmallMedium = "ArQuickLinks-module--ar-paragraph-xsmall-medium--2pFMs";
export var arLinkMobile = "ArQuickLinks-module--ar-link-mobile--1cFT2";
export var arLinkDesktop = "ArQuickLinks-module--ar-link-desktop--2q4rk";
export var arQuote = "ArQuickLinks-module--ar-quote--OraJV";
export var arQuoteMobile = "ArQuickLinks-module--ar-quote-mobile--1PKZr";
export var arGradientLight = "ArQuickLinks-module--ar-gradient-light--3FDLp";
export var arGradientBlue = "ArQuickLinks-module--ar-gradient-blue--3BoZS";
export var arTransition = "ArQuickLinks-module--ar-transition--20XHa";
export var container = "ArQuickLinks-module--container--3rdeU";